<template>
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
      <div class="container-fluid">
        <router-link class="navbar-brand" to="/">Webbutvecklare</router-link>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav">
            <li class="nav-item">
              <router-link class="nav-link" to="/" exact-active-class="active" @click="toggleMenu">Hem</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/about" exact-active-class="active" @click="toggleMenu">Om mig</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/services" exact-active-class="active" @click="toggleMenu">Tjänster</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/contact" exact-active-class="active" @click="toggleMenu">Kontakta mig</router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </template>
  
  <script>
  export default {
    name: 'Navbar',
    methods: {
      toggleMenu() {
        const navbarCollapse = document.querySelector('.navbar-collapse');
        if (navbarCollapse.classList.contains('show')) {
          navbarCollapse.classList.remove('show');
        }
      }
    }
  }
  </script>
  
  <style scoped>
  .nav-link.active {
    font-weight: bold; /* Gör den aktiva länken fet */
    text-decoration: underline; /* Understryk den aktiva länken */
  }
  </style>
  