import { createRouter, createWebHistory } from 'vue-router'
import Home from '../components/Home.vue'
import About from '../components/About.vue'
import Services from '../components/Services.vue'
import Contact from '../components/Contact.vue'

const routes = [
  { path: '/', component: Home },        // Startsidans rutt
  { path: '/about', component: About },   // Om mig-sidan
  { path: '/services', component: Services }, // Tjänster-sidan
  { path: '/contact', component: Contact } // Kontakta mig-sidan
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
