<template>
    <div class="container text-center">
      <h1 class="my-5">Välkommen till min portfölj</h1>
      <p>Jag skapar moderna och responsiva hemsidor för småföretag.</p>
      <router-link to="/services" class="btn btn-primary">Se mina tjänster</router-link>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Home'
  }
  </script>
  
  <style scoped>
  </style>
  