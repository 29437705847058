<template>
    <div class="container">
      <h2>Kontakta mig</h2>
      <form @submit.prevent="submitForm">
        <div class="mb-3">
          <label for="name" class="form-label">Namn</label>
          <input type="text" class="form-control" id="name" v-model="form.name" required />
        </div>
        <div class="mb-3">
          <label for="email" class="form-label">E-post</label>
          <input type="email" class="form-control" id="email" v-model="form.email" required />
        </div>
        <div class="mb-3">
          <label for="message" class="form-label">Meddelande</label>
          <textarea class="form-control" id="message" v-model="form.message" required></textarea>
        </div>
        <button type="submit" class="btn btn-primary">Skicka</button>
      </form>
      <div v-if="successMessage" class="alert alert-success mt-3">{{ successMessage }}</div>
      <div v-if="errorMessage" class="alert alert-danger mt-3">{{ errorMessage }}</div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        form: {
          name: '',
          email: '',
          message: ''
        },
        successMessage: '',
        errorMessage: ''
      };
    },
    methods: {
      async submitForm() {
        try {
          const response = await fetch('/send_email.php', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(this.form)
          });
  
          const result = await response.json();
  
          if (result.success) {
            this.successMessage = 'Ditt meddelande har skickats!';
            this.errorMessage = '';
            this.form = { name: '', email: '', message: '' }; // Rensa formuläret
          } else {
            this.errorMessage = 'Det gick inte att skicka meddelandet. Försök igen.';
            this.successMessage = '';
          }
        } catch (error) {
          this.errorMessage = 'Ett fel inträffade. Försök igen.';
          this.successMessage = '';
        }
      }
    }
  };
  </script>
  
  <style scoped>
  </style>
  