<template>
    <div class="container">
      <h1 class="my-5">Om mig</h1>
      <p>
        Jag är en passionerad webbutvecklare med erfarenhet av att skapa moderna och användarvänliga hemsidor.
      </p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'About'
  }
  </script>
  
  <style scoped>
  </style>
  